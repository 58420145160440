import { ItasMinusIcon, ItasPlusIcon, RightArrowIcon } from 'src/assets'
import { lowerLanguage } from 'src/i18n'
import { Faq } from 'src/types'
import LandingAccordion from 'src/ui/fragments/landing-accordion/Accordion'
import { Button } from 'src/ui/widgets'

import { useSection8 } from './section8.hook'

export const Section8 = () => {
  const { t, faqs, numberOfFaqToShow, goToFaqs } = useSection8()

  const Title = (
    <h3 className="mb-4 section8-dynamic-size-title d-flex align-item-center text-uppercase">
      {t('landing.section_8_title')}
    </h3>
  )

  const Subtitle = (
    <div className="mb-4 section8-dynamic-size-subtitle d-flex align-items-center white-space-pre-subtitle">
      {t('landing.section_8_subtitle')}
    </div>
  )

  const ButtonForm = (
    <div className="mb-5">
      <Button
        text={t('landing.section_8_all_faqs')}
        iconRight={RightArrowIcon}
        classesIconRight="ms-3 filter-ffffff"
        iconSize={18}
        classes="p-2 ps-3 pe-3 d-flex justify-content-center bg-2727ab"
        classesText="font-14-ag-500 c-ffffff"
        onPress={goToFaqs}
      />
    </div>
  )

  const Faqs = (
    <div className="pt-4 pt-md-0">
      <div>
        {faqs?.slice(0, numberOfFaqToShow).map((item: Faq) => {
          return (
            <LandingAccordion
              key={item?.id}
              title={item?.request?.[lowerLanguage]}
              classes_title="m-0 p-0 me-3 section8-dynamic-size-faqs-title col-8"
              icon_size={24}
              icon_open={ItasMinusIcon}
              icon_close={ItasPlusIcon}
              button_read={false}
              classes="ps-md-3 pe-md-3 section8-class"
              classes_icon_open="section8-open-icon"
              separator="section8-separator"
            >
              <div
                className="py-2 section8-dynamic-size-faqs-info white-space-pre mb-5"
                dangerouslySetInnerHTML={{ __html: item?.answer?.[lowerLanguage] || '' }}
              />
            </LandingAccordion>
          )
        })}
      </div>
    </div>
  )

  return (
    <div className="col-xl-12 d-xl-flex justify-content-center">
      <div className="col-xl-6">
        {Title}
        {Subtitle}
        {ButtonForm}
      </div>
      <div className="col-xl-6">{Faqs}</div>
    </div>
  )
}
