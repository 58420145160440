import { useAws } from 'src/networking/aws'
import { useAxios } from 'src/networking/axios'
import {
  AwsGateCallbacks,
  AwsGateRequest,
  AxiosGateCallbacks,
  AxiosGateRequest,
  GetCookie,
  GetUserTransaction,
  PostCertificate,
  PostEvaluatePremium,
  PostPayment,
  PostPdf,
  PostQuote,
} from 'src/types'

export const usePostQuote = <T extends PostQuote['request'], D extends PostQuote['response']>() => {
  const hook = useAws<T, D>('postQuote')
  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      return hook.crossGate(
        {
          method: 'post',
          path: '/quote',
          ...request,
        },
        callbacks
      )
    },
  }
}

export const usePostEvaluatePremium = <
  T extends PostEvaluatePremium['request'],
  D extends PostEvaluatePremium['response']
>() => {
  const hook = useAws<T, D>('postEvaluatePremium')
  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      return hook.crossGate(
        {
          method: 'post',
          path: '/evaluate-premium',
          ...request,
        },
        callbacks
      )
    },
  }
}

export const usePostPayment = <
  T extends PostPayment['request'],
  D extends PostPayment['response']
>() => {
  const hook = useAws<T, D>('postPayment')
  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      return hook.crossGate(
        {
          method: 'post',
          path: '/payment',
          ...request,
        },
        callbacks
      )
    },
  }
}

export const usePostPdf = <T extends PostPdf['request'], D extends PostPdf['response']>() => {
  const hook = useAws<T, D>('postPdf')
  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      return hook.crossGate(
        {
          method: 'post',
          path: '/itas-rcs-transaction-get-pdf-function',
          ...request,
        },
        callbacks
      )
    },
  }
}

export const usePostCertificate = <
  T extends PostCertificate['request'],
  D extends PostCertificate['response']
>() => {
  const hook = useAws<T, D>('postCertificate')
  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      const { contractId, securityHash } = request.params
      return hook.crossGate(
        {
          method: 'post',
          path: `/certificate/${contractId}?securityHash=${securityHash}`,
          ...request,
        },
        callbacks
      )
    },
  }
}

export const useGetCookie = <T extends GetCookie['request'], D extends GetCookie['response']>() => {
  const hook = useAxios<T, D>('getCookie')
  return {
    ...hook,
    crossGate: (request: AxiosGateRequest<T>, callbacks: AxiosGateCallbacks<D>) => {
      return hook.crossGate(
        {
          method: 'get',
          ...request,
        },
        callbacks
      )
    },
  }
}

export const useGetUserTransaction = <
  T extends GetUserTransaction['request'],
  D extends GetUserTransaction['response']
>() => {
  const hook = useAws<T, D>('getUserTransaction')
  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      return hook.crossGate(
        {
          method: 'post',
          path: '/get-user-transactions',
          ...request,
        },
        callbacks
      )
    },
  }
}
