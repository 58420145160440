/* eslint-disable complexity */
import './entry-group-step2.scss'

import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { lowerLanguage } from 'src/i18n'
import { usePostGetGroup } from 'src/networking/terminals'
import {
  atomEvaluatePremium,
  atomFormikMainInsuredGroup,
  selectorDocumentsGroup,
} from 'src/storage'
import { Formik, Gender, InputRadioOption, Nationality } from 'src/types'
import {
  FormikGroupStep2,
  FormikMainInsuredGroup,
  GroupFile,
} from 'src/types/networking/terminals/group.types'
import { useBoolean } from 'src/utils'

import { useValidationSchemasGroup1 } from '../../handlers/validation-schemas-group.hook'

export const useEntryGroupStep2 = ({
  formikFather,
}: {
  formikFather: Formik<FormikMainInsuredGroup>
}) => {
  const { t } = useTranslation()

  const { setFieldValue } = formikFather

  // Constants
  const nationalityOptions = [
    { id: 'IT', value: t('data_entry_step_2.insured_nationality_italy') },
    { id: 'EXT', value: t('data_entry_step_2.insured_nationality_foreign') },
  ]

  const fcQuestionOptions = [
    { id: 'yes_id', value: t('data_entry_step_2.insured_fc_question_yes') },
    { id: 'no_id', value: t('data_entry_step_2.insured_fc_question_no') },
  ]

  const genderOptions: InputRadioOption[] = [
    {
      id: 'M',
      value: t('claim_report_open.male'),
    },
    {
      id: 'F',
      value: t('claim_report_open.female'),
    },
  ]

  // Recoils
  const resetFormikMainGroup = useResetRecoilState(atomFormikMainInsuredGroup)
  const resetEvaluatedPremium = useResetRecoilState(atomEvaluatePremium)
  const documents = useRecoilValue(selectorDocumentsGroup)

  // States
  const [filesGroup, setFilesGroup] = useState<File[]>([])
  const [isModalVisible, showModal, hideModal] = useBoolean(false)
  const [initalValueFormikModal, saveFormikModal] = useState<FormikGroupStep2>({
    id: 0,
    name: '',
    surname: '',
    nationality: '',
    fiscalCodeQuestion: '',
    birthDate: '',
    birthNation: '',
    birthPlace: '',
    gender: '',
    fiscalCode: '',
  })

  // CrossGates
  const { crossGate: postGetGroup } = usePostGetGroup()

  // UseEffect
  useEffect(() => {
    if (filesGroup && filesGroup.length > 0) {
      setTimeout(async () => {
        let groupFiles: GroupFile[] = []
        await Promise.all(
          Object?.entries(filesGroup)?.map(async ([_, file]) => {
            const file_ = await formatFile(file)
            groupFiles = [...groupFiles, file_]
          })
        )

        const orderedGroupFiles = groupFiles?.sort((a, b) => a?.name?.localeCompare(b?.name))

        postGetGroup(
          {
            body: {
              groupFiles: orderedGroupFiles,
              language: lowerLanguage,
            },
          },
          {
            onSuccess: (data) => {
              setFieldValue('group', data?.group)

              let status: 'OK' | 'PARTIAL_ERRORS' | 'ERRORS' | undefined = undefined

              if (!data?.hasErrors) {
                status = 'OK'
              } else if (data?.hasErrors === 'PARTIAL_ERRORS') {
                status = 'PARTIAL_ERRORS'
              } else if (data?.hasErrors === 'ERRORS') {
                status = 'ERRORS'
              }

              setFieldValue('filesStatus', status)
              setFieldValue('fileNames', orderedGroupFiles?.map((item) => item?.name) || [])
            },
          }
        )
      }, 1000)
    }
  }, [filesGroup])

  ////////////////////////////////////////////////////////////////////////////////////////////

  const validationSchema = useValidationSchemasGroup1()

  const formikModal = useFormik<FormikGroupStep2>({
    initialValues: initalValueFormikModal,
    validationSchema: validationSchema,
    onSubmit: (values, { setTouched, setSubmitting }) => {
      setTouched({})

      saveFormikModal(values)

      const updatedGroup = [...(formikFather?.values?.group || {})]

      updatedGroup[values?.id || 0] = {
        name: values?.name || '',
        surname: values?.surname || '',
        nationality: (values?.nationality as Nationality) || '',
        birthDate: values?.birthDate || '',
        birthNation: values?.birthNation || '',
        birthPlace: values?.birthPlace || '',
        gender: (values?.birthPlace as Gender) || '',
        fiscalCode: values?.fiscalCode || '',
        fiscalCodeQuestion: values?.fiscalCodeQuestion || '',
      }

      setFieldValue('group', updatedGroup)

      hideModal()

      setSubmitting(false)
    },
  })

  // Functions

  const onDownloadFile = () => {
    window.open(documents?.[0]?.file[lowerLanguage], '_blank')
  }

  const formatFile = async (file: File): Promise<GroupFile> => {
    const formattedFile: GroupFile = await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = async () => {
        resolve({
          name: file?.name || '',
          size: file?.size || 0,
          data: reader.result?.toString() || '',
          type: file?.type || '',
          lastModified: file?.lastModified || 0,
        })
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })

    return formattedFile
  }

  const onShowModalEditPerson = (index: number) => {
    showModal()
    const item = formikFather?.values?.group?.[index]

    formikModal?.setFieldValue('id', index)
    formikModal?.setFieldValue('name', item?.name || '')
    formikModal?.setFieldValue('surname', item?.surname || '')
    formikModal?.setFieldValue('fiscalCodeQuestion', item?.fiscalCode ? 'yes_id' : 'no_id')
    formikModal?.setFieldValue('nationality', item?.nationality || '')
    formikModal?.setFieldValue('birthDate', item?.birthDate || '')
    formikModal?.setFieldValue('birthNation', item?.birthNation || '')
    formikModal?.setFieldValue('birthPlace', item?.birthPlace || '')
    formikModal?.setFieldValue('gender', item?.gender || '')
    formikModal?.setFieldValue('fiscalCode', item?.fiscalCode || '')
  }

  const onRemoveFile = () => {
    resetFormikMainGroup()
    resetEvaluatedPremium()

    setFieldValue('group', [])
    setFieldValue('filesStatus', undefined)
    setFieldValue('fileNames', [])
  }

  return {
    t,
    filesGroup,
    formikModal,
    genderOptions,
    isModalVisible,
    validationSchema,
    fcQuestionOptions,
    nationalityOptions,
    hideModal,
    onRemoveFile,
    setFilesGroup,
    onDownloadFile,
    onShowModalEditPerson,
  }
}
